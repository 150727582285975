import React from 'react'

import {Box, chakra} from '@chakra-ui/react'

export const Logo = (props: any) => {
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      width="full"
      height="full"
      viewBox="0 0 475 475"
      sx={{
        '.squarel': {
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: '#000000'
        },
        '.k': {
          fill: '#FFFFFF'
        }
      }}
      {...props}>
      <g id="Ebene_1-2">
        <g id="layer1">
          <path
            id="Fill_1"
            className="squarel"
            d="M425.2,49.62C380.42,4.99,316.03,0,237.33,0S94.09,5.05,49.31,49.78,0,158.9,0,237.59s4.53,143.14,49.31,187.87c44.78,44.73,109.23,49.26,188.02,49.26s143.24-4.53,188.02-49.26c44.78-44.73,49.31-109.12,49.31-187.87,.05-78.8-4.58-143.29-49.46-187.97h0Z"
          />
        </g>
        <path
          className="k"
          d="M 251.95,186.11
             C 271.68,179.85 285.98,161.38 285.98,139.57
               285.98,112.61 264.12,90.75 237.15,90.75
               210.19,90.75 188.33,112.61 188.33,139.57
               188.33,161.36 202.60,179.82 222.30,186.10
               222.30,186.10 91.00,383.06 91.00,383.06
               91.00,383.06 204.82,383.06 204.82,383.06
               204.82,383.06 172.16,328.20 172.16,328.20
               172.16,328.20 237.15,237.01 237.15,237.01
               237.15,237.01 302.15,328.20 302.15,328.20
               302.15,328.20 269.49,383.06 269.49,383.06
               269.49,383.06 383.31,383.06 383.31,383.06
               383.31,383.06 251.95,186.11 251.95,186.11 Z
             M 261.57,139.57
             C 261.57,153.06 250.64,163.99 237.15,163.99
               223.67,163.99 212.74,153.06 212.74,139.57
               212.74,126.09 223.67,115.16 237.15,115.16
               250.64,115.16 261.57,126.09 261.57,139.57 Z"
        />
      </g>
    </chakra.svg>
  )
}

export default Logo
